import {
    rqget,
    rqpost,
    upfiles
} from '../utils/request';
export function login(logininfo) {
    return rqget("/api/login", logininfo);
}
export function getaccounttype(param){
    return rqget("/api/getaccounttype", param);
}
export function adduser(param){
    return rqpost("/api/adduser", param);
}
export function addcollection(param){
    return rqpost("/api/addcollection", param);
}
export function getcollections(param){
    return rqpost("/api/getcollections", param);
}
export function getcollectioninfo(param){
    return rqget("/api/getcollectioninfo", param);
}
export function getcollectionimages(param){
    return rqget("/api/getcollectionimages", param);
}
export function saveimages(param){
    return rqget("/api/saveimages", param);
}
export function editcomment(param){
    return rqpost("/api/editcomment", param);
}
export function savelogo(param){
    return rqpost("/api/savelogo", param);
}
export function getlogolist(param){
    return rqget("/api/getlogolist", param);
}
export function stoplogo(param){
    return rqpost("/api/stoplogo", param);
}
export function dellogo(param){
    return rqpost("/api/dellogo", param);
}
export function addgroup(param){
    return rqpost("/api/addgroup", param);
}
export function getallfacegroup(param){
    return rqget("/api/getallfacegroup", param);
}
export function savefaceuser(param){
    return rqpost("/api/savefaceuser", param);
}
export function getgroupuser(param){
    return rqget("/api/getgroupuser", param);
}
export function replaceuserface(param){
    return rqpost("/api/replaceuserface", param);
}
export function editusername(param){
    return rqpost("/api/editusername", param);
}
export function savefaceset(param){
    return rqpost("/api/savefaceset", param);
}
export function condeleteface(param){
    return rqpost("/api/condeleteface", param);
}
export function changefacedatabase(param){
    return rqpost("/api/changefacedatabase", param);
}
export function batchsearchface(param){
    return rqpost("/api/batchsearchface", param);
}
export function saveedit(param){
    return rqpost("/api/saveedit", param);
}
export function switchshow(param){
    return rqpost("/api/switchshow", param);
}
export function updatadate(param){
    return rqpost("/api/updatadate", param);
}
export function setcover(param){
    return rqpost("/api/setcover", param);
}
export function savechangeimage(param){
    return rqpost("/api/savechangeimage", param);
}
export function packagedownload(param){
    return rqpost("/api/packagedownload", param);
}
export function getdownlink(param){
    return rqpost("/api/getdownlink", param);
}
export function pmdgetimages(param){
    return rqpost("/api/pmdgetimages", param);
}
export function pmdaddtoshopcar(param){
    return rqpost("/api/pmdaddtoshopcar", param);
}
export function admingetcollectionimages(param){
    return rqpost("/api/admingetcollectionimages", param);
}
export function checkoldpassword(param){
    return rqpost("/api/checkoldpassword", param);
}
export function setpassword(param){
    return rqpost("/api/setpassword", param);
}
export function getinvitecode(){
    return rqget("/api/getinvitecode");
}
export function getusers(){
    return rqget("/api/getusers");
}
export function saveauth(param){
    return rqpost("/api/saveauth", param);
}
export function openlock(param){
    return rqpost("/api/openlock", param);
}
export function gettags(){
    return rqget("/api/gettags");
}
export function pmdgetcollection(param){
    return rqpost("/api/pmdgetcollection",param);
}
export function pmdgetcollectionshopcarlist(param){
    return rqpost("/api/pmdgetcollectionshopcarlist",param);
}
export function pmdgetshopcarlist(param){
    return rqpost("/api/pmdgetshopcarlist",param);
}
export function deletecollection(param){
    return rqpost("/api/deletecollection",param);
}
export function copycollection(param){
    return rqpost("/api/copycollection",param);
}
export function changeauthor(param){
    return rqpost("/api/changeauthor",param);
}
export function delimage(param){
    return rqpost("/api/delimage",param);
}
export function changecollectionauthor(param){
    return rqpost("/api/changecollectionauthor",param);
}
export function autorecom(param){
    return rqpost("/api/autorecom",param);
}
export function mergetwocollection(param){
    return rqpost("/api/mergetwocollection",param);
}
export function getdeteledcollection(){
    return rqget("/api/getdeteledcollection");
}
export function recoverycollection(param){
    return rqpost("/api/recoverycollection",param);
}
export function adddownlog(param){
    return rqpost("/api/adddownlog",param);
}
export function getdownlogs(param){
    return rqget("/api/getdownlogs",param);
}
export function pmdpackagedownload(param){
    return rqpost("/api/pmdpackagedownload",param);
}
export function pmdsearchface(param){
    return rqpost("/api/pmdsearchface",param);
}
export function geteditlogs(param){
    return rqpost("/api/geteditlogs",param);
}
export function pmdhitnumcount(param){
    return rqget("/api/pmdhitnumcount",param);
}
export function pmdbrowsesnumcount(param){
    return rqget("/api/pmdbrowsesnumcount",param);
}
export function editinvitecode(param){
    return rqpost("/api/editinvitecode",param);
}
export function deletefacedatabase(param){
    return rqpost("/api/deletefacedatabase",param);
}
export function savefacedbname(param){
    return rqpost("/api/savefacedbname",param);
}
export function changeusertype(param){
    return rqpost("/api/changeusertype",param);
}
export function getdownpackagepath(param){
    return rqpost("/api/getdownpackagepath",param);
}
export function adminsearch(param){
    return rqpost("/api/adminsearch",param);
}
export function pmdsearch(param){
    return rqpost("/api/pmdsearch",param);
}
export function sendtelcode(param){
    return rqpost("/api/sendtelcode",param);
}
export function changepassword(param){
    return rqpost("/api/changepassword",param);
}
export function pmdgettags(){
    return rqget("/api/pmdgettags");
}
export function addcomment(param){
    return rqpost("/api/addcomment",param);
}
export function getcommentlist(param){
    return rqget("/api/getcommentlist",param);
}
export function savesetuserinfo(param){
    return rqpost("/api/savesetuserinfo",param);
}
export function getuserstoset(param){
    return rqget("/api/getuserstoset",param);
}
export function addnewlabel(param){
    return rqpost("/api/addnewlabel",param);
}
export function getinterlabellist(){
    return rqget("/api/getinterlabellist");
}
export function changeinnerlabel(param){
    return rqpost("/api/changeinnerlabel",param);
}
export function packagedownloadsearch(param){
    return rqpost("/api/packagedownloadsearch",param);
}
export function getzhangshu(param){
    return rqget("/api/getzhangshu",param);
}
export function gettheuserauth(param){
    return rqget("/api/gettheuserauth",param);
}
export function pmdgetthebigtag(){
    return rqget("/api/pmdgetthebigtag");
}
export function autodate(param){
    return rqpost("/api/autodate",param);
}
export function addagreement(param){
    return rqpost("/api/addagreement",param);
}
export function getagreement(param){
    return rqpost("/api/getagreement",param);
}
export function pmdgettheuserinfo(param){
    return rqpost("/api/pmdgettheuserinfo",param);
}
export function tongjiyongliang(param){
    return rqpost("/api/tongjiyongliang",param);
}
export function pmdpackagedownloadnew(param){
    return rqpost("/api/pmdpackagedownloadnew",param);
}
export function pmdgetoldpicture(param){
    return rqpost("/api/pmdgetoldpicture",param);
}
