import {
  getzhangshu,
  gettheuserauth
} from "@/api/user";
const Auth = {
  //加密
  async getauth(cotag, taglist) {
    let sysuser = JSON.parse(localStorage.getItem("sysuser"));
    let ust = await this.gettheuserauth()
    sysuser.tag_auth = ust.tag_auth;
    if (sysuser.tag_auth != '' && sysuser.tag_auth != null && sysuser.tag_auth != '0') {
      let tag_autharray = sysuser.tag_auth.split(",");
      console.log(tag_autharray)
      let tag = taglist.find((item) => {
        return item.name == cotag;
      });
      let tagauth = tag_autharray.includes(tag.id);
      let imageauth = false;
      if (sysuser.image_auth != "10") {
        imageauth = true;
      }
      if (tagauth && imageauth) {
        return true;
      } else {
        return false;
      }
    } else if (sysuser.tag_auth == '0') {
      return true;
    } else if (sysuser.tag_auth != '' || sysuser.tag_auth == null) {
      return false;
    }
  },
  changezhangshu() { },
  //解密
  async downauth(item) {
    let url = "";
   let sysuser = JSON.parse(localStorage.getItem("sysuser"));
  // return  await this.gettheuserauth().then(sysuser=>{
      if (sysuser.image_auth == '1') {
        url =
          "https://basketballimages.oss-cn-beijing.aliyuncs.com/" +
          item.path_original;
        console.log(url)
        return url
      } else if (sysuser.image_auth == '2') {
        url =
          "https://basketballimages.oss-cn-beijing.aliyuncs.com/" +
          item.path_compress;
        console.log(url)
        return url
      } else if (sysuser.image_auth == '8') {
        if (
          item.path_compress_logo == null ||
          item.path_compress_logo == ""
        ) {
          url =
            "https://basketballimages.oss-cn-beijing.aliyuncs.com/" +
            item.path_compress;
          console.log(url)
          return url
        } else {
          url =
            "https://basketballimages.oss-cn-beijing.aliyuncs.com/" +
            item.path_compress_logo;
          console.log(url)
          return url
        }
      } else if (sysuser.image_auth == '9') {
        if (
          item.path_idmark == null ||
          item.path_idmark == ""
        ) {
          url =
            "https://basketballimages.oss-cn-beijing.aliyuncs.com/" +
            item.path_compress;
          console.log(url)
          return url
        } else {
          url =
            "https://basketballimages.oss-cn-beijing.aliyuncs.com/" +
            item.path_idmark;
          console.log(url)
          return url
        }
      }
   //  })

    
   

  },
  async zhangshuauth(num) {
    let sysuser = JSON.parse(localStorage.getItem("sysuser"));
    let used_number = 0;
    let total_number = 0;
    await getzhangshu({ param: { sysid: sysuser.id } }).then(res => {
      used_number = parseInt(res.data.used_number)
      total_number = parseInt(res.data.total_number)
      console.log(res)
      console.log(total_number)
    })
    let newnum = used_number + num;
    console.log(newnum)
    console.log(total_number)
    if (newnum <= total_number) {
      sysuser.used_number = used_number + num
      sysuser.total_number = total_number
      localStorage.setItem("sysuser", JSON.stringify(sysuser))
      return true
    } else {

      return false
    }

  },
  gettheuserauth() {
    let sysuser = JSON.parse(localStorage.getItem("sysuser"));
    return gettheuserauth({ param: { id: sysuser.id } }).then(res => {
      sysuser.tag_auth_name = res.data.tag_auth_name;
      sysuser.image_auth_name = res.data.image_auth_name;
      sysuser.used_number = parseInt(res.data.used_number)
      sysuser.total_number = parseInt(res.data.total_number)
      localStorage.setItem("sysuser", JSON.stringify(sysuser))
      return res.data
    })
  },

}

export default Auth