import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'
import Base64 from "../src/utils/aes";
import Auth from "../src/utils/auth";
import VueJustifiedLayout from 'vue-justified-layout'
import'./assets/font/font.css'
import './assets/themscss.scss'
import vueLazy from 'vue-lazyload'
import VueSignaturePad from "vue-signature-pad";
import VueClipboard from 'vue-clipboard2'



Vue.use(VueClipboard)
Vue.use(VueSignaturePad);
Vue.use(vueLazy)
Vue.use(VueJustifiedLayout)
Vue.config.productionTip = false;
Vue.prototype.Base64= Base64;
Vue.prototype.Auth= Auth;



//或者自定义配置插件
// Vue.use(VueLazyload, {
// preLoad: 1.3,
// error: require('../src/assets/admin-1.svg'),
// loading: require('../src/assets/admin-1.svg'),
// attempt: 1
// })

Vue.use(ElementUI, { locale });
// Vue.filter('fmtisshow', val => {
//     if (val === 1 || val === "1") {
//         return '男';
//     } else {
//         return '女'
//     }

// });
new Vue({
    data:function(){
        return{
            sysuser:''
        }
    },
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");