import axios from 'axios';
import Qs from 'qs';
// import { Encrypt, Decrypt } from '../util/aes.js';

axios.defaults.baseURL = 'https://api.basketballimages.cn' //测试

//post请求头
axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded;charset=UTF-8";
// axios.interceptors.request.use(
//     config => {
//         if (localStorage.getItem('Authorization')) {
//             config.headers.Authorization = localStorage.getItem('Authorization');
//         }
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     });

// axios.interceptors.response.use(
//     response => {
//         console.log(response)
//         return response
//     },
//     error => {
//         console.log(error)
//     }
// )


//设置超时
axios.defaults.timeout = 100000000000000;

export function rqget(url, params) {
    return axios.get(url, {
        params
    }).catch(error => {
        // const {
        //     response: {
        //         status,
        //         statusText
        //     }
        // } = error;
        return Promise.reject(error);
    });
}
export function rqpost(url, params) {
    // console.log(info);
    return axios.post(url, Qs.stringify(params)).catch(error => {
        // const {
        //     response: {
        //         status,
        //         statusText
        //     }
        // } = error;

        return Promise.reject(error);
    });
}
export function upfiles(url, data, config) {
    return axios.post(url, data, config)
}
export default {

    rqget,
    rqpost,
    upfiles
}