import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'album',
        component: () =>
            import( /* webpackChunkName: "layout" */ "../layouts/Mainalbum.vue"),
        children: [{
                path: "/album",
                redirect: "/album/index"

            }, {
                path: "/album/index",
                name: "index",
               
                meta: {
                    albumnam:'album',
                    keepAlive: true,
                    title:'篮球影像库|首页'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "faceset" */ "../views/album/Index"),
            }, 
            {
                path: "/album/oldpicture",
                name: "oldpicture",
               
                meta: {
                    albumnam:'album',
                    keepAlive: true,
                    title:'篮球影像库|老照片'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "faceset" */ "../views/album/Oldpicture"),
            }, 
            {
                path: "/album/atlas",
                name: "atlas",
                meta: {
                    albumnam:'album',
                    keepAlive: true,   
                    title:'篮球影像库'                 
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "faceset" */ "../views/album/Atlas"),
            },  
            {
                path: "/album/shopcar",
                name: "shopcar",
                meta: {
                    keepAlive: true,
                    albumnam:'album',
                    title:'篮球影像库|收藏夹'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "faceset" */ "../views/album/Shopcar"),
            },   
            {
                path: "/album/personalcenter",
                name: "personalcenter",
                meta: {
                    keepAlive: true,
                    albumnam:'album',
                    title:'篮球影像库|个人中心'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "faceset" */ "../views/album/Personalcenter"),
            },  
            {
                path: "/album/uploadimgs",
                name: "uploadimgs",
                meta: {
                    keepAlive: true,
                    albumnam:'album',
                    title:'篮球影像库|上传图片'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "faceset" */ "../views/album/Uploadimgs.vue"),
            },   
            {
                path: "/album/sequelimages",
                name: "sequelimages",
                meta: {
                    keepAlive: true,
                    albumnam:'album',
                    title:'篮球影像库|续传图片'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "faceset" */ "../views/album/Sequelimages.vue"),
            },    
            {
                path: "/album/editimages",
                name: "editimages",
                meta: {
                    keepAlive: true,
                    albumnam:'album',
                    title:'篮球影像库|查看图片'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "faceset" */ "../views/album/Editimages.vue"),
            },     
            {
                path: "/album/aboutus",
                name: "aboutus",
                meta: {
                    keepAlive: true,
                    albumnam:'album',
                    title:'篮球影像库|关于我们'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "faceset" */ "../views/album/Aboutus.vue"),
            },    
            // {
            //     path: "/group/sequelimages",
            //     name: "sequelimages",
            //     meta: {
            //         keepAlive: true
            //     },
            //     // route level code-splitting
            //     // this generates a separate chunk (about.[hash].js) for this route
            //     // which is lazy-loaded when the route is visited.
            //     component: () =>
            //         import( /* webpackChunkName: "index" */ "../views/group/sequelimages"),
            // },



        ]
    },
    {
        path: '/searchresults',
        name: 'searchresults',
        component: () =>
            import( /* webpackChunkName: "layout" */ "../layouts/Mainhead.vue"),
        children: [{
                path: "/searchresults",
                redirect: "/searchresults/index"

            }, {
                path: "/searchresults/index",
                name: "index",
                meta: {
                    keepAlive: true,
                    pathroute:[
                        {
                            info:'篮球影像库',
                            path:'/group/index',
                            isactive:false
                        },
                        {
                            info:'/检索结果',
                            path:'/searchresults/index',
                            isactive:true
                        },                              
                    ],
                    title:'后台|检索结果'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "index" */ "../views/Searchresults/Index"),
            },


        ]
    },
    {
        path: '/albumsearchresults',
        name: 'albumsearchresults',
        component: () =>
            import( /* webpackChunkName: "layout" */ "../layouts/Mainalbum.vue"),
        children: [{
                path: "/searchresults",
                redirect: "/albumsearchresults/index"
            }, {
                path: "/albumsearchresults/index",
                name: "index",
                meta: {
                    keepAlive: true,
                    albumnam:'album',
                    title:'篮球影像库|检索结果'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import( /* webpackChunkName: "index" */ "../views/Albumsearchresults/Index"),
            },


        ]
    },
    {
        path: '*',
        name: '404',
        meta:{
            title:'404'
        },
        component: () =>
            import(
                "../views/404.vue")
    },  
    {
        path: '/login/agreement',
        name: 'agreement',
        meta:{
            title:'篮球影像库|签订协议'
        },
        component: () =>
            import(
                "../views/login/Agreement.vue")
    },  
    {
        path: '/downloadpackage',
        name: 'downloadpackage',
        meta:{
            title:'篮球影像库'
        },
        component: () =>
            import(
                "../views/login/Downloadpackage")
    },  
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
      }
    
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title   
    console.log(to)
    if (to.path == '/') {
        next({
            path: '/album/index'
        })

    }
    let sysuser = localStorage.getItem('sysuser');
    if (sysuser == null && to.path !== '/login/index' && to.path !== '/album/index' && to.path !== '/downloadpackage'&& to.path !== '/login/agreement' ) {
        if(to.path=='/album/atlas'){
            next({
                path: '/album/index'
            })
        }else{
        next({
            path: '/album/index'
        })
    }
    }
    if (to.path !== from.path) {
        NProgress.start();
    }
    console.log(from.path)
    NProgress.done();
    next();
});
router.afterEach(() => {
    NProgress.done();
})

export default router;